<!--设置页面-->
<template>
    <div class="processSet">
        <header>
            <div class="el_left title">
                设置页面
            </div>
        </header>
        <div class="processSet-content">
            <div class="nav el-left">
                <ul class="nav-list">
                    <li
                        :class="{'nav-item':true,active:index===curComp}"
                        v-for="(item,index) in componentLs"
                        :key="index"
                        @click="selectNav(index)"
                    >
                        <i :class="['iconfont',item.icon]"></i><span>{{ item.title }}</span>
                    </li>
                </ul>
            </div>
            <div class="set-content">
                <component :businessid="businessid" :is="componentLs[curComp].component"></component>
            </div>
        </div>
    </div>
</template>

<script>
import segmentSetting from './segmentSetting';
import formSetting from './formSetting';
export default {
    name: 'process-set',
    components: { segmentSetting, formSetting },
    props: {
        extr: Object,
    },
    data() {
        return {
            businessid: '',
            curComp: 0,
            componentLs: [
                {
                    title: '环节设置',
                    component: segmentSetting,
                    icon: 'iconshuaxin',
                },
                {
                    title: '表单设置',
                    component: formSetting,
                    icon: 'iconbiaodan',
                },
            ],
        };
    },
    created() {
        this.businessid = this.extr.businessid;
    },
    methods: {
        selectNav(index) {
            this.curComp = index;
        },
    },
};
</script>

<style lang="stylus" scoped>
.processSet
    width 100%
    height 100%
    background #F4F7FD
    header
        width: 100%;
        height: 0.6rem;
        background: #6B7EBE;
        .title
            display flex
            align-items center;
            height 100%;
            font-size 0.18rem;
            padding-left 0.2rem;
            color #fff;
    .processSet-content
        height 100%;
        display flex
        .nav
            width 1.6rem;
            height 100%;
            .nav-list
                padding 0.1rem 0;
                width 100%;
                overflow-y auto;
                .nav-item
                    width: 1.6rem;
                    height: 0.6rem;
                    background: #F4F7FD;
                    border: 0.02rem solid #FFFFFF;
                    display flex
                    justify-content center
                    align-items center
                    font-size 0.18rem;
                    color #666666
                    cursor pointer
                    &.active
                        background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                        color #fff;
                    .iconfont
                        margin-right 0.08rem;
        .set-content
            width calc(100% - 1.6rem)
        .next
            text-align center
</style>
